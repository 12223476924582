import { Link as GatsbyLink } from "gatsby-plugin-react-i18next";
import {
  LeadSection,
  Post,
  Card,
  Button,
  Section,
  CardGrid,
  Title,
} from "@lleed-partners/factor";
import {
  ArrowRight32,
  Time32,
  WatsonHealthMagnify32,
  Scales32,
  Tools32,
  Loop32,
  Locked32,
  UserMultiple32,
  Dashboard32,
} from "@carbon/icons-react";
import { graphql } from "gatsby";
import { useI18next } from "gatsby-plugin-react-i18next";
import { Icon } from "../fragments/Icon";
import { Layout } from "../layouts";
import { Helmet } from "gatsby-plugin-react-i18next";
import { asBackground } from "../fragments/styles";
import { StaticImage } from "gatsby-plugin-image";
import pattern from "../images/common/pattern.svg";

const patternStyle = {
  backgroundImage: "url('" + pattern + "')",
};

export default () => {
  const { t, language } = useI18next();

  return (
    <Layout
      navProps={{
        showSubNav: false,
      }}
    >
      <div style={patternStyle}>
        <Helmet>
          <meta
            name="description"
            content="Audit the performance of your company. Lleed & Partners offers a free digital audit to help you with your digital transition."
          />
          <title>Free digital audit</title>
        </Helmet>
        <LeadSection
          intent="ghostInverted"
          padTop
          style={{
            minHeight: "100vh",
          }}
          title="Audit your digital practices"
          subtitle={
            <>
              Want to know how you perform, which processes, if any, you could
              improve?
              <br />
              <br />
              Lleed & Partners offers a free digital auditing service to help
              business understand bottlenecks and attempt to remedy.
            </>
          }
          cta={
            <>
              <Button
                size="large"
                intent="white"
                as="a"
                href="#audit"
                rightIcon={<Icon icon={<ArrowRight32 />} />}
              >
                Digital audit program
              </Button>
            </>
          }
        />
        <div
          id="audit"
          style={{
            backgroundColor: "rgba(0,0, 0, .2)",
          }}
        >
          <Section
            intent="ghostInverted"
            title={
              <Title
                title="The key steps"
                subtitle="to improving your everyday operations"
              />
            }
          >
            <CardGrid size="large" intent="white" bordered>
              <Card title="Measure" icon={<Icon icon={<Time32 />} />}>
                Improving is not possible if you don't know where you stand at.
                Start by assessing the time and money you spend on everyday
                operations.
              </Card>
              <Card
                title="Analyze"
                icon={<Icon icon={<WatsonHealthMagnify32 />} />}
              >
                Use your performance report results to detect tasks that take an
                abnormal amount of resources. It is best to then focus on the
                tasks that have the most potential for a streamlining: common
                processes where there is a lot of overhead.
              </Card>
              <Card title="Judge" icon={<Icon icon={<Scales32 />} />}>
                From the performance analysis, decide on which, if any, levers
                to act. Depending on your operations, it might occur that your
                time and effort should rather be spent somewhere else than
                changing a process that seemingly works. However, it is common
                that some results may demande special care.
              </Card>
              <Card title="Act" icon={<Icon icon={<Tools32 />} />}>
                Review your procedures, digitalize what can be. Reduce
                back-and-forth by setting up automated validation. Use machine
                learning to detect potential erroneous documents. The solutions
                are various and will greatly vary based on your use case.
              </Card>
              <Card title="Iterate" icon={<Icon icon={<Loop32 />} />}>
                Repeat this process on a regular basis. Inefficient processes
                can be created at any time, and new technological advances can
                make some old processes easier to replace or streamline. Always
                assess your performance on a regular basis to detect improvement
                or regression.
              </Card>
            </CardGrid>
          </Section>
          <Post intent="ghostInverted">
            <h2>Most companies don't assess their efficiency correctly</h2>
            <p>
              Assessing your performance is not an easy task without external
              counseling. Some key bottlenecks might be ignored due to
              preconceived ideas or a habit bias.
            </p>
            <p>
              Lleed & Partners offers a free digital audit<sup>*</sup> to help
              you grasp your current efficiency. This process is divided into
              the steps described below.
            </p>
            <div style={{ height: "6rem" }}></div>
          </Post>
        </div>
        <Section
          title={
            <Title
              title="Lleed & Partners"
              subtitle="Free digital audit program"
              cta={
                <Button
                  size="large"
                  as={GatsbyLink}
                  to="/contact/business/"
                  rightIcon={<Icon icon={<ArrowRight32 />} />}
                >
                  Request a free digital audit
                </Button>
              }
            >
              Our digital audit program is comprised of few, simple steps.
            </Title>
          }
          intent="dark"
        >
          <CardGrid intent="secondary" size="large">
            <Card icon={<Icon icon={<Locked32 />} />} title="Ensuring privacy">
              Customer privacy is our top priority. Before starting any
              auditing, we will make sure that your information is within good
              hands.
            </Card>
            <Card icon={<Icon icon={<UserMultiple32 />} />} title="Shadowing">
              A team of Lleed & Partners business experts will be deployed at
              your office and will shadow your employees during a given time
              period. They will be able to provide the most relevant data to
              create a performance report.
            </Card>
            <Card icon={<Icon icon={<Dashboard32 />} />} title="Reporting">
              The data collected during the shadowing period will be reported
              and graphed for further analysis. These documents will be provided
              free of charge to discuss relevant improvements, if any, with your
              business team.
            </Card>
            <Card icon={<Icon icon={<ArrowRight32 />} />} title="Handover">
              Upon discussion with your business team, a plan of action will be
              outlined. From then on, you can choose to continue working with
              Lleed & Partners on some or all of the actions, choose to work
              with another third party or choose not to act.
            </Card>
          </CardGrid>
        </Section>
        <LeadSection
          backgroundMedia={
            <StaticImage
              src="https://images.pexels.com/photos/8550496/pexels-photo-8550496.jpeg"
              alt=""
              style={asBackground}
              loading="eager"
            />
          }
          inline
          title="Get started today"
          subtitle="Request a free digital audit to assess your operations and improve your performance."
          cta={
            <Button
              intent="white"
              size="large"
              as={GatsbyLink}
              to="/contact/business/"
              rightIcon={<Icon icon={<ArrowRight32 />} />}
            >
              Contact Lleed & Partners
            </Button>
          }
        />
        <Section
          intent="ghostInverted"
          style={{
            backgroundColor: "rgba(0, 0, 0, .2)",
          }}
        >
          <Post intent="ghostInverted">
            <p>
              <small>
                *The Lleed & Partners free digital audit program is subject to
                conditions. Lleed & Partners reserve the right to approve
                candidates or reject applications without justification.
              </small>
            </p>
          </Post>
        </Section>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
